.reset_password__container {
  color: #200865;
  height: 100dvh;
  padding: 0 24px;
  overflow: hidden;
  width: 90%;
  margin: 0 auto;

  @media (min-width: 1280px) {
    width: 100%;
    margin: 0 auto;
    padding: 0 70px;
    overflow: hidden;
  }
}

.logo__header_diff_step_three {
  margin-top: 9px;

  .logo_diff_step_three {
    font-size: 22.222px;
    color: #200865;
  }

  .div_diff_step_three {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .button_diff_step_three {
    place-self: start;
    margin-top: 20px;
  }

  @media (min-width: 1280px) {
    margin-top: 26px;
    display: flex;
    flex-direction: column;

    .logo_diff_step_three {
      font-size: 26.133px;
    }
  }
}

.section_diff_step_three {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100dvh;

  .section_div_diff_step_three {
    width: 100%;

    .section_header_diff_step_three {
      h1 {
        color: #200865;
        font-size: 24px;
      }

      p {
        font-size: 14px;
      }
    }

    .form {
      margin-top: 40px;
    }

    @media (min-width: 1280px) {
      width: 464px;

      .section_header_diff_step_three {
        h1 {
          color: #200865;
          font-size: 40px;

          @media (max-width: 390px) {
            color: #200865;
            font-size: 24px;
          }
        }

        p {
          font-size: 16px;

          @media (max-width: 390px) {
            font-size: 14px;
          }
        }
      }
    }

    @media (max-width: 290px) {
      width: 100%;
    }
  }
}

.submit__btn_diff_step_three {
  margin-top: 180px;
  margin-bottom: 60px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    font-size: 16px;
    height: 50px;
    color: #fff;
    background-color: #713fff;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    margin-top: 200px;
  }

  @media (min-width: 530px) and (max-height: 730px) {
    margin-top: 100px;
  }

  @media (min-width: 1024px) and (max-height: 600px) {
    margin-top: 60px;
  }

  @media (max-width: 375px) and (max-height: 667px) {
    margin-top: 100px;
  }
}

.change_email {
  color: #713fff;
}

.code_container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (min-width: 1024px) {
    gap: 24px;
  }

  .code {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #d1cfd6;
    background: #fff;
    text-align: center;

    @media (max-width: 460px) {
      width: 40px;
      height: 40px;
    }
  }
}

.resend {
  color: #713fff;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  margin-top: 16px;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}

// Step three styles

.reset_password__Step_three {
  color: #200865;
  height: 100dvh;
  padding: 0 24px;
  overflow: hidden;
  width: 90%;
  margin: 0 auto;

  @media (min-width: 1024px) {
    width: 100%;
    margin: 0 auto;
    padding: 0 70px;
    overflow: hidden;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    div {
      header {
        width: 100%;
        margin-top: 40px;

        h1 {
          color: #200865;
          font-size: 24px;
        }

        p {
          font-size: 14px;
        }
      }

      .form {
        width: 100%;
      }

      @media (min-width: 1024px) {
        header {
          width: 464px;

          h1 {
            font-size: 40px;
          }

          p {
            font-size: 16px;
          }
        }

        .form {
          width: 464px;
        }
      }
    }
  }
}

.logo__header {
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    gap: 10px;

    .logo {
      color: #200865;
      font-size: 22.222px;
    }
  }

  button {
    place-self: start;
    margin-top: 10px;
  }

  @media (min-width: 1024px) {
    margin-top: 26px;

    div {
      .logo {
        font-size: 26.133px;
      }
    }
  }
}

.submit__btn {
  margin-top: 180px;
  margin-bottom: 60px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 50px;
    color: #fff;
    background-color: #713fff;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    margin-top: 200px;
  }

  @media (min-width: 530px) and (max-height: 730px) {
    margin-top: 100px;
  }

  @media (min-width: 1024px) and (max-height: 600px) {
    margin-top: 60px;
  }

  @media (max-width: 375px) and (max-height: 667px) {
    margin-top: 100px;
  }
}

// Step four styles

.section_step_four {
  width: 100%;
  margin: 100px auto;

  .reset_success {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    svg {
      text-align: center;
      margin-bottom: 32px;
    }

    h1 {
      color: #200865;
      font-size: 24px;
    }
  }

  .stepFour_submit__btn {
    height: 50px;
    color: #fff;
    background-color: #713fff;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 100px;
  }

  @media (min-width: 1280px) {
    width: 464px;
    margin: 70px auto;

    .stepFour_submit__btn {
      margin-top: 120px;
    }
  }

  @media (min-width: 530px) and (max-height: 730px) {
    margin-top: 100px;
  }

  @media (min-width: 1024px) and (max-height: 600px) {
    margin-top: 60px;
  }

  @media (max-width: 375px) and (max-height: 667px) {
    margin-top: 100px;
  }
}

.validate_aggregator {
  margin-top: 30px;
  padding-left: 20px;
  color: #200865;

  @media (max-width: 600px) {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 100px);

    .validating_text {
      padding-top: 20px;
      font-size: 16px;
    }
  }
}
