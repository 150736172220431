.card_container {
  width: 100%;
  border-radius: 20px;
  height: 126px;
  overflow: hidden;

  @media (min-width: 1280px) {
    min-width: 300px;
  }
}

.title_container {
  width: 100%;
  height: 126px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .title {
    color: #fff;
    cursor: pointer;
  }
}

.credit_balance {
  color: #fff;
  margin-bottom: -10px;
  strong {
    font-size: 40px;
  }
}

.loan_balance {
  color: #fff;
  margin-bottom: -10px;
  font-style: normal;
  font-size: 32px;
  line-height: normal;
  font-weight: 600;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 10px 0;

  .content_wrap {
    padding: 11px 0 11px 20px;
    p {
      color: #170d33;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 18.2px */
    }
    button {
      padding: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #713fff;
      font-size: 14px;
      line-height: 140%;
      font-weight: 700;
      border: none;
      background-color: transparent;

      .action_word {
        color: #713fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
      }

      .arrowRight {
        width: 16px;
        height: 16px;
      }
    }
  }

  @media (min-width: 1280px) {
    padding: 0;
  }
}

.accountDetails_container {
  width: 100%;
  height: 126px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;

  @media (min-width: 1280px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 0;
  }

  @media (max-width: 290px) and (max-height: 655px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }

  .loader {
    place-self: center;
  }

  .userDetails {
    place-self: start;
  }
}

.accountDetails {
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 290px) and (max-height: 655px) {
    place-self: start;
  }

  .bank_personal_info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    p {
      &:first-child {
        color: #200865;
        font-size: 14px;
        font-style: normal;
        line-height: 16px;
        font-weight: 700;

        @media (min-width: 1280px) {
          margin-top: 20px;
          font-size: 17.382px;
          line-height: 19.865px;
        }
      }

      &:last-child {
        color: #200865;
        place-self: start;
        font-size: 12px;
        font-style: normal;
        line-height: 16px;
        font-weight: 400;

        @media (min-width: 1280px) {
          margin-top: -10px;
          font-size: 14.899px;
          line-height: 19.865px;
        }
      }
    }
  }

  .copy {
    cursor: pointer;
  }
}

.bank_name {
  color: #200865;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  @media (min-width: 1280px) {
    font-size: 17.382px;
  }

  @media (max-width: 290px) and (max-height: 655px) {
    place-self: start;
  }
}
