.direct_debit__container {
    height: 100vh;
    overflow: hidden;
    // padding: 0 24px;
    color: #200865;
    width: 90%;
    margin: 0 auto;

    @media (min-width: 1280px) {
        width: 100%;
        padding: 0 70px;
    }

    .logo__header {
        margin-top: 26px;
        display: flex;
        align-items: center;
        gap: 10px;
    
        .logo {
            font-size: 26.133px;
        }
    }

    .body {
        height: calc(100vh - 100px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .failedSvg {
            width: 300px;
            height: 200px;
    
            @media (min-width: 1280px) {
                width: 400px;
            }
        }

        .message {
            color: #000;
            margin-top: 10px;
            font-size: 20px;
        
            @media (min-width: 360px) {
                font-size: 16px;
            }
        }
    }
    
}