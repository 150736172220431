.stepOne_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 100px;

    @media (max-width: 290px) and (max-height: 653px) {
        gap: 0;

        .content_wrap {
            .content {
                .title {
                    font-size: 18px;
                }
    
            }
        }
    }
}

.submit_button {
    height: 45px;
    color: #fff;
    background-color: #713FFF;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    border: none;
}

.cancel_button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.skip_button {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
}

.content_wrap {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .featureIcon {
        width: 144px;
        flex-shrink: 0;
        height: 144px;
        border-radius: 50%;
        background-color: #F8F5FF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
    }

    .content {
        width: 100%;

        .title {
            color: #170D33;
            font-size: 20px;
            text-align: center;
            font-weight: 700;
            font-style: normal;
            line-height: 125%;
        }

        .content_body {
            color: #8B8699;
            font-size: 14px;
            text-align: center;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0.4px;
            line-height: 22px;
        }
    }

    @media (min-width: 1280px) {
        .content {
            width: 361px;
    
            .title {
                font-size: 32px;
            }
        }
    }
}