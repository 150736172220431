.userType_container {
    width: 100%;

    @media (min-width: 1280px) {
        width: 464px;
    }
    .userType_header {
        margin-top: 20px;
        h1 {
            color: #200865;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 125%;
            font-family: "DM Sans";
        }
    }

    .role {
        width: 100%;
    
        .individual {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 20px;
            border: 0.5px solid  #E8E7EA;
            padding: 16px;
            margin-top: 40px;
            cursor: pointer;

            &:hover {
                background-color: #F8F5FF;
            }
    
            .group1 {
                display: flex;
                align-items: center;
                gap: 16px;
    
                .userContainer {
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                    background-color: #eeebf5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
            }
    
        }
    
        .business {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 20px;
            border: 0.5px solid  #E8E7EA;
            padding: 16px;
            margin-top: 24px;
            cursor: pointer;

            &:hover {
                background-color: #F8F5FF;
            }
    
            .group2 {
                display: flex;
                align-items: center;
                gap: 16px;
    
                .businessContainer {
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                    background-color: #eeebf5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    
        @media (min-width: 1026px) {
            max-width: 464px;
        }
    
        @media (max-width: 280px) and (max-width: 653px) {
            .individual {
                padding: 8px;
                margin-top: 16px;
        
                .group1 {
                    gap: 8px;
        
                    .userContainer {
                        height: 40px;
                        width: 40px;
                    }
                    
                }
        
            }
        
            .business {
                padding: 8px;
                margin-top: 16px;
        
                .group2 {
                    gap: 8px;
        
                    .businessContainer {
                        height: 40px;
                        width: 40px;
                    }
                }
            }
        }
    }
    
    .text {
        p {
            &:first-child {
                font-size: 20px;
                font-weight: 700;
                color: #170D33;
    
                @media (max-width: 280px) and (max-width: 653px) {
                    font-size: 16px;
                }
            }
    
            &:last-child {
                font-size: 14px;
                font-weight: 400;
                color: #8B8699;
    
                @media (max-width: 280px) and (max-width: 653px) {
                    font-size: 10px;
                }
            }
    
        }
    
    }
}

