.modal_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 200px);
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  border-top: 4px solid #cfbeff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  overflow: scroll;
  z-index: 10;

  @media (min-width: 1280px) {
    width: 528px;
    height: 720px;
    top: 10px;
    right: 20px;
    padding: 40px 32px;
    transform: none;
    left: auto;
  }

  .cancel_button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.stepOne_container {
  position: relative;

  .arrow {
    position: absolute;
    top: 15px;
    left: 0;
    cursor: pointer;

    @media (min-width: 1280px) {
      top: 0;
      left: 0;
    }
  }
  .stepOne_header {
    padding-top: 50px;
    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
    }

    h3 {
      color: #170d33;
      font-size: 24px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
    }

    p {
      color: #8b8699;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .stepOne_form {
    text-align: left;
    margin-top: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .loader {
      margin-top: 10px;
      place-self: start;
    }

    .selectProductContainer {
      width: 100%;
      position: relative;
      margin-bottom: 12px;

      .product_input_field_container {
        position: relative;

        .input_pointer {
          position: absolute;
          right: 5px;
          top: 7px;
        }
      }

      .productNameDropdown {
        max-height: 150px;
        position: absolute;
        background-color: #fff;
        overflow-y: auto;
        color: #000;
        list-style: none;
        z-index: 100;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;

        &::-webkit-scrollbar {
          width: 0.6rem;
        }

        &::-webkit-scrollbar-thumb {
          width: 0.4rem;
          height: 3rem;
          background-color: #200865;
          border-radius: 0.4rem;
        }
      }
    }

    .selectTenorContainer {
      width: 100%;
      position: relative;
      margin-bottom: 12px;

      .tenor_input_field_container {
        position: relative;

        .input_pointer {
          position: absolute;
          right: 5px;
          top: 7px;
        }
      }

      .tenorValueDropdown {
        max-height: 150px;
        position: absolute;
        background-color: #fff;
        overflow-y: auto;
        color: #000;
        list-style: none;
        z-index: 100;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;

        &::-webkit-scrollbar {
          width: 0.6rem;
        }

        &::-webkit-scrollbar-thumb {
          width: 0.4rem;
          height: 3rem;
          background-color: #200865;
          border-radius: 0.4rem;
        }
      }

      .customTenorContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

        .customRepaymentValue {
          &:first-of-type {
            margin-bottom: 12px;
          }
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .switcher {
          margin-top: 10px;
          place-self: end;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .submit_button {
      margin-top: 50px;
    }
  }

  .input_container {
    margin-bottom: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .alert {
    color: rgb(204, 10, 10);
    font-size: 13px;
  }

  .input_label_container {
    display: flex;
    flex-direction: column;
    flex: 1;

    label {
      font-size: 90%;
      color: #8b8699;
      line-height: 12px;
    }

    .error {
      border-color: rgb(218, 6, 6);
    }
  }

  .input_field {
    display: block;
    height: 40px;
    padding: 0 0.7rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1e2022;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d7dce0;
    border-radius: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;

    &::placeholder {
      color: #8c98a4;
      opacity: 1;
    }
  }
}

.submit_button {
  margin-top: 70px;
  margin-bottom: 30px;
  height: 45px;
  color: #fff;
  background-color: #713fff;
  font-weight: 500;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.submit_button_loading_state {
  margin-top: 70px;
  margin-bottom: 30px;
  height: 45px;
  color: #fff;
  background-color: #713fff;
  font-weight: 500;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
}

.stepTwo_container {
  position: relative;

  .arrow {
    position: absolute;
    top: 15px;
    left: 0;
    cursor: pointer;

    @media (min-width: 1280px) {
      top: 0;
      left: 0;
    }
  }

  .stepTwo_header {
    padding-top: 50px;
    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
    }

    h3 {
      color: #170d33;
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
    }

    p {
      color: #8b8699;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .loanAmount_container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 132px;
    flex-shrink: 0;
    border-radius: 18.38px;
    background-color: #200865;
    color: #fff;

    .title {
      text-align: center;
      font-style: normal;
      font-size: 16px;
      line-height: normal;
      font-weight: 400;
    }

    .credit_balance {
      strong {
        color: #fff;
        font-size: 32px;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
      }
    }

    @media (min-width: 1280px) {
      width: 462px;

      .title {
        text-align: center;
        font-style: normal;
        font-size: 16px;
        line-height: normal;
        font-weight: 400;
      }

      .credit_balance {
        strong {
          color: #fff;
          font-size: 32px;
          font-weight: 600;
          font-style: normal;
          line-height: normal;
        }
      }
    }
  }

  .submit_button {
    margin-top: 150px;

    @media (min-width: 1280px) {
      margin-top: 140px;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      margin-top: 80px;
    }
  }
}

.stepThree_container {
  .stepThree_header {
    padding-top: 25px;
    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
    }

    h3 {
      color: #170d33;
      font-size: 24px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
    }

    p {
      color: #8b8699;
      font-size: 16px;
      font-family: "DM Sans";
      font-weight: 400;
      font-style: normal;
      line-height: 150%; /* 24px */
    }

    @media (max-width: 375px) and (max-height: 667px) {
      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .loanOffer_container {
    width: 100%;
    height: max-content;
    background: #f8f5ff;
    flex-shrink: 0;
    border-radius: 10px;

    .loanOffer_overview {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .loan_title {
        color: #041644;
        font-size: 16px;
        font-family: "DM Sans";
        font-weight: 700;
        font-style: normal;
        line-height: 125%;
        border-bottom: 1px solid #d7dce0;
        grid-column: span 2;
        padding: 0 30px;
        padding-bottom: 12px;
        padding-top: 20px;

        @media (min-width: 1280px) {
          font-size: 18px;
        }
      }

      span {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 0 30px;

        @media (max-width: 290px) and (max-height: 653px) {
          padding: 0 20px;
        }

        small {
          color: #81909d;
          font-family: "DM Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }

        strong {
          color: #0d0f11;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          text-transform: capitalize;
        }
      }
    }

    .loan_schedule {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      text-align: center;

      .title {
        color: #041644;
        font-size: 16px;
        font-family: "DM Sans";
        font-weight: 700;
        font-style: normal;
        line-height: 125%;
        border-bottom: 1px solid #d7dce0;
        grid-column: span 2;
        padding: 0 30px;
        padding-bottom: 12px;
        padding-top: 10px;
        grid-column: 1 / -1;

        @media (min-width: 1280px) {
          font-size: 18px;
        }
      }

      .loan_schedule_data {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        padding-left: 30px;

        .serial_number {
          width: 20px;
          height: 20px;
          background-color: #81909d;
          border-radius: 50%;
          text-align: center;
        }

        @media (max-width: 290px) and (max-height: 653px) {
          padding-left: 20px;
        }

        .schedule_amount_date {
          display: flex;
          flex-direction: column;

          small {
            color: #81909d;
            font-family: "DM Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }

          strong {
            color: #0d0f11;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            font-family: "DM Sans";
            line-height: 150%;
          }
        }
      }

      @media (min-width: 1280px) {
        grid-template-columns: repeat(3, 1fr);
      }

      .view_more_schedule_btn_container {
        grid-column: 1 / -1;
        padding-bottom: 20px;
        padding-top: 10px;

        .view_more_schedule_btn {
          background-color: #713fff;
          color: #fff;
          padding: 5px 10px;
          border-radius: 10px;
        }
      }
    }

    @media (min-width: 1280px) {
      width: 462px;
    }
  }
}

.stepFour_container {
  position: relative;

  .arrow {
    position: absolute;
    top: 15px;
    left: 0;
    cursor: pointer;

    @media (min-width: 1280px) {
      top: 0;
      left: 0;
    }
  }
  .stepFour_header {
    padding-top: 50px;
    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
      padding-top: 40px;
    }

    h3 {
      color: #170d33;
      font-size: 24px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
    }

    p {
      color: #8b8699;
      font-size: 16px;
      font-family: "DM Sans";
      font-weight: 400;
      font-style: normal;
      line-height: 150%; /* 24px */
    }

    @media (max-width: 375px) and (max-height: 667px) {
      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .loanAggreement_container {
    padding: 0 10px;

    @media (min-width: 1280px) {
      padding: 0px;
    }
  }
}

.stepFive_container {
  .stepFive_header {
    padding-top: 25px;
    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
    }

    h3 {
      color: #170d33;
      font-size: 24px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
    }

    p {
      color: #8b8699;
      font-size: 16px;
      font-family: "DM Sans";
      font-weight: 400;
      font-style: normal;
      line-height: 150%; /* 24px */
    }

    @media (max-width: 375px) and (max-height: 667px) {
      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .loanOffer_container {
    width: 100%;
    height: max-content;
    background: #f8f5ff;
    flex-shrink: 0;
    border-radius: 10px;

    .loanOffer_overview {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .loan_title {
        color: #041644;
        font-size: 16px;
        font-family: "DM Sans";
        font-weight: 700;
        font-style: normal;
        line-height: 125%;
        border-bottom: 1px solid #d7dce0;
        grid-column: span 2;
        padding: 0 30px;
        padding-bottom: 12px;
        padding-top: 20px;

        @media (min-width: 1280px) {
          font-size: 18px;
        }
      }

      span {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 0 30px;

        @media (max-width: 290px) and (max-height: 653px) {
          padding: 0 20px;
        }

        small {
          color: #81909d;
          font-family: "DM Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }

        strong {
          color: #0d0f11;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          font-family: "DM Sans";
          line-height: 150%;
          text-transform: capitalize;
        }
      }
    }

    .loan_schedule {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      text-align: center;

      .title {
        color: #041644;
        font-size: 16px;
        font-family: "DM Sans";
        font-weight: 700;
        font-style: normal;
        line-height: 125%;
        border-bottom: 1px solid #d7dce0;
        grid-column: span 2;
        padding: 0 30px;
        padding-bottom: 12px;
        padding-top: 10px;
        grid-column: 1 / -1;

        @media (min-width: 1280px) {
          font-size: 18px;
        }
      }

      .loan_schedule_data {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        padding-left: 30px;

        .serial_number {
          width: 20px;
          height: 20px;
          background-color: #81909d;
          border-radius: 50%;
          text-align: center;
        }

        @media (max-width: 290px) and (max-height: 653px) {
          padding-left: 20px;
        }

        .schedule_amount_date {
          display: flex;
          flex-direction: column;

          small {
            color: #81909d;
            font-family: "DM Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }

          strong {
            color: #0d0f11;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            font-family: "DM Sans";
            line-height: 150%;
          }
        }
      }

      @media (min-width: 1280px) {
        grid-template-columns: repeat(3, 1fr);
      }

      .view_more_schedule_btn_container {
        grid-column: 1 / -1;
        padding-bottom: 20px;
        padding-top: 10px;

        .view_more_schedule_btn {
          background-color: #713fff;
          color: #fff;
          padding: 5px 10px;
          border-radius: 10px;
        }
      }
    }

    @media (min-width: 1280px) {
      width: 462px;
    }
  }
}

.stepSix_container {
  width: 100%;
  height: 100%;
  margin-top: 50%;
  text-align: center;

  @media (min-width: 1280px) {
    margin-top: 40%;
  }

  .verification_failed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box {
      width: 74px;
      height: 74px;
      background-color: #fff;
      border: 3px solid #000;
      border-radius: 50%;
      position: relative;
      margin-bottom: 50px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 35px;
        background-color: #e43535;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    h3 {
      color: #3a434b;
      font-size: 18px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    p {
      color: #3a434b;
      font-family: "DM Sans";
      text-align: center;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }
}

.stepSeven_container {
  width: 100%;
  height: 100%;
  margin-top: 50%;
  text-align: center;

  @media (min-width: 1280px) {
    margin-top: 40%;
  }

  .verification_success {
    img {
      margin-bottom: 50px;
    }

    h3 {
      color: #3a434b;
      font-size: 24px;
      font-family: "DM Sans";
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.2px;
      font-style: normal;
    }

    p {
      color: #3a434b;
      font-family: "DM Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-align: center;
    }
  }
}

.stepEight_container {
  position: relative;

  .arrow {
    position: absolute;
    top: 15px;
    left: 0;
    cursor: pointer;

    @media (min-width: 1280px) {
      top: 0;
      left: 0;
    }
  }

  .stepEight_header {
    padding-top: 50px;
    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
    }
    h3 {
      color: #170d33;
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
    }

    p {
      color: #8b8699;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

.stepNine_container {
  width: 100%;
  height: 100%;
  margin-top: 50%;
  text-align: center;

  @media (min-width: 1280px) {
    margin-top: 30%;
  }

  .verification_failed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box {
      width: 74px;
      height: 74px;
      background-color: #fff;
      border: 3px solid #000;
      border-radius: 50%;
      position: relative;
      margin-bottom: 50px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 35px;
        background-color: #e43535;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    h3 {
      color: #3a434b;
      font-size: 18px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    p {
      color: #3a434b;
      font-family: "DM Sans";
      text-align: center;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }

  .user_options {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
      &:first-child {
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        display: flex;
        width: 100%;
        height: 50px;
        padding: 12px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #713fff;
      }

      &:last-child {
        color: #713fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        display: flex;
        width: 100%;
        height: 50px;
        padding: 12px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #562eed;
        background: #f8f5ff;
      }
    }
  }
}
.submit__btn {
  margin-top: 70px;
  // margin-bottom: 68px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;

  button {
    height: 50px;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    border: none;
  }
}

.stepTen_container {
  position: relative;

  .arrow {
    position: absolute;
    top: 15px;
    left: 0;
    cursor: pointer;

    @media (min-width: 1280px) {
      top: 0;
      left: 0;
    }
  }

  .stepTen_header {
    padding-top: 50px;

    @media (min-width: 1280px) {
      padding-top: 40px;
    }

    h3 {
      color: #200865;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 30px */

      @media (min-width: 1280px) {
        color: #170d33;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
      }
    }

    @media (max-width: 375px) and (max-height: 667px) {
      h3 {
        font-size: 18px;
      }
    }
  }

  .bankStatement_instructions {
    width: 100%;
    height: 302px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #f6f5f7;
    padding: 20px 10px;

    p {
      color: #3a434b;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18px */
    }

    ul {
      padding-left: 20px;
      li {
        color: #3a434b;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
      }
    }
  }

  button {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    display: flex;
    width: 100%;
    height: 50px;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: #713fff;
    margin-top: 70px;
  }
}

.stepEleven_container {
  position: relative;

  .arrow {
    position: absolute;
    top: 15px;
    left: 0;
    cursor: pointer;

    @media (min-width: 1280px) {
      top: 0;
      left: 0;
    }
  }

  .stepEleven_form {
    padding-top: 50px;
    text-align: left;

    @media (min-width: 1280px) {
      text-align: left;
    }

    .stepEleven_header {
      h3 {
        color: #170d33;
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        line-height: 32px;
      }

      p {
        color: #8b8699;
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      @media (max-width: 375px) and (max-height: 667px) {
        h3 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .selectBankContainer {
      width: 100%;
      position: relative;
      margin-bottom: 8px;
      // @media (min-width: 400px) and (max-width: 414px) {
      //     margin-top: -40px;
      // }
      // @media (min-width: 416px) and (max-width: 470px) {
      //     margin-top: -58px;
      // }
      .bankNameDropdown {
        max-height: 150px;
        position: absolute;
        background-color: #fff;
        overflow-y: auto;
        color: #000;
        list-style: none;
        z-index: 100;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
      }
      .selectedBankField {
        width: 100%;
        border: 1px solid #d1cfd6;
        padding: 6px 10px;
        border-radius: 8px;
        font-size: 16px;
        &::placeholder {
          color: #8c98a4;
          opacity: 1;
        }
      }
      .bank_error {
        display: inline-block;
        color: rgb(205, 3, 3);
        margin-top: 4px;
        font-size: 13px;
      }
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

  button {
    width: 100%;
    border-radius: 10px;
    height: 45px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

 //product dive initial step styles
.productdive_container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .arrow {
    position: absolute;
    top: 15px;
    left: 0;
    cursor: pointer;

    @media (min-width: 1280px) {
      top: 0;
      left: 0;
    }
  }

  .productdive_header {
    padding-top: 30px;
    text-align: center;

    h3 {
      color: #170d33;
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
      text-transform: capitalize;
    }

    p {
      color: #8b8699;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .productdive_loan_amount_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 132px;
    flex-shrink: 0;
    border-radius: 18.38px;
    background-color: #F8F5FF;

    .title {
      text-align: center;
      font-style: normal;
      font-size: 16px;
      line-height: normal;
      font-weight: 400;
      color: #8B8699
    }

    .credit_balance {
      color: #170D33;
      strong {
        font-size: 32px;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
      }
    }

    .discount {
      color: #25AD25;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    @media (min-width: 1280px) {
      width: 462px;

      .title {
        text-align: center;
        font-style: normal;
        font-size: 16px;
        line-height: normal;
        font-weight: 400;
        color: #8B8699
      }

      .credit_balance {
        color: #170D33;
        strong {
          font-size: 32px;
          font-weight: 600;
          font-style: normal;
          line-height: normal;
        }
      }
    }
  }

  .productdive_percentage_info {
    width: 100%;
    height: 80px;
    background-color: #FEF4E5;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
    border-radius: 10px;

    .percentage_text {
      color: #C37E0B;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;

      @media (min-width: 1280px) {
        color: #C37E0B;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }

  .part_payment_amount {
    color: #0E81FF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  .productdive_submit_button {
    margin-top: 70px;
    margin-bottom: 30px;
    height: 45px;
    color: #fff;
    background-color: #713fff;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    border: none;
  }
}


