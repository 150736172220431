.modal_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 200px);
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  border-top: 4px solid #cfbeff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  z-index: 10;
  overflow: scroll;

  @media (min-width: 1280px) {
    width: 528px;
    height: 720px;
    top: 10px;
    right: 20px;
    padding: 40px 32px;
    transform: none;
    left: auto;
  }

  .modal_steps_wrapper {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .cancel_button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.stepOne_container {
  margin-top: 50px;
  @media (min-width: 1280px) {
    margin-top: 0;
  }
  width: 100%;
  height: 100%;
  display: flex;
  // align-items: center;
  flex-direction: column;
  // justify-content: center;
  // gap: 100px;

  .stepOne_header {
    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
    }
    h3 {
      color: #170d33;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    p {
      color: #3a434b;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .stepOne_form {
    text-align: left;
    margin-top: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .input_container {
      display: flex;
      flex-direction: column;

      span {
        color: rgb(204, 10, 10);
      }
    }

    .input_label_container {
      display: flex;
      flex-direction: column;
      flex: 1;

      label {
        font-size: 90%;
        color: #8b8699;
        line-height: 12px;
      }

      .input_field {
        display: block;
        height: 40px;
        padding: 0 0.7rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #1e2022;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d7dce0;
        border-radius: 10px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        width: 100%;
      }
    }
  }
}

.stepTwo_container {
  margin-top: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: center;

  .stepTwo_header {
    h3 {
      color: #170d33;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }

  .stepTwo_instruction {
    margin-top: 30px;
    .transfer_amount {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .instruction {
      text-align: left;
    }
  }

  .accountDetails_container {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;
    background: #f8f5ff;
    flex-shrink: 0;
    border-radius: 20px;
    padding: 10px;

    .bank_personal_info {
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .info {
        color: #8b8699;
        font-size: 14px;
        font-family: "DM Mono";
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.4px;
        line-height: 24px;
      }
      .account_number {
        color: #170d33;
        font-size: 24px;
        font-family: "DM Mono";
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0.2px;
        line-height: 24px;
      }
    }

    @media (min-width: 1280px) {
      width: 462px;

      .bank_personal_info {
        .account_number {
          font-size: 24px;
        }
      }
    }

    .copyIcon {
      place-self: end;
      cursor: pointer;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      margin-top: 20px;
    }

    @media (max-width: 360px) and (max-height: 740px) {
      margin-top: 20px;
    }

    @media (max-width: 540px) and (max-height: 720px) {
      margin-top: 20px;
    }

    @media (max-width: 290px) and (max-height: 653px) {
      margin-top: 20px;
    }
  }

  @media (max-width: 375px) and (max-height: 667px) {
    .stepTwo_instruction {
      margin-top: 10px;
    }
  }

  @media (max-width: 360px) and (max-height: 740px) {
    .stepTwo_instruction {
      margin-top: 10px;
    }
  }

  @media (max-width: 540px) and (max-height: 720px) {
    .stepTwo_instruction {
      margin-top: 10px;
    }
  }

  @media (max-width: 290px) and (max-height: 653px) {
    .stepTwo_instruction {
      margin-top: 10px;
    }
  }

  .backArrow {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }
}

.submit_button {
  margin-top: 50px;
  padding-bottom: 40px;
  font-weight: 500;
  width: 100%;

  @media (min-width: 1280px) {
    margin-top: 70px;
  }

  button {
    width: 100%;
    height: 45px;
    border-radius: 10px;
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    margin-top: 10%;
  }
}

.stepThree_container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 70px;
}

.stepFour_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .verification_success {
    svg {
      margin-bottom: 50px;
    }

    h3 {
      color: #3a434b;
      font-size: 18px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    p {
      color: #3a434b;
      font-family: "DM Sans";
      text-align: center;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }

  @media (min-width: 1280px) {
    .verification_success {
      svg {
        margin-bottom: 50px;
      }

      h3 {
        font-size: 24px;
      }
    }
  }
}

.stepFive_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .verification_failed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box {
      width: 74px;
      height: 74px;
      background-color: #fff;
      border: 3px solid #000;
      border-radius: 50%;
      position: relative;
      margin-bottom: 50px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 35px;
        background-color: #e43535;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    h3 {
      color: #3a434b;
      font-size: 18px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    p {
      color: #3a434b;
      font-family: "DM Sans";
      text-align: center;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }

  @media (min-width: 1280px) {
    .verification_failed {
      h3 {
        font-size: 24px;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}