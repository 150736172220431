.stepTwo_container {
    width: 100%;

    @media (min-width: 1280px) {
        width: 464px;
    }

    .stepTwo_header {
        margin-top: 30px;

        h1 {
            color: #200865;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 125%; /* 40px */
            font-family: "DM Sans";
        }
    }
    .stepTwo_form {
        width: 100%;
        
        @media (min-width: 1280px) {
            width: 464px;
        }

        .otp {
            // position: absolute;
            margin: 20px 0;
            color: #713FFF;
            cursor: pointer;
            // top: 130px;

            .cursor_disabled {
                cursor: wait;
            }

            .cursor_pointer {
                cursor: pointer;
            }
        }
    }

    .more_choices {
        text-align: center;
        margin-top: 30px;

        .action_button {
            color: #713FFF;
            border: none;
            background-color: transparent;
        }
    }
}

.submit_button {
    margin-top: 10px;
    height: 45px;
    color: #fff;
    background-color: #713FFF;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}