.modal_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 200px);
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  border-top: 4px solid #cfbeff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  overflow: scroll;
  z-index: 10;

  @media (min-width: 1280px) {
    width: 528px;
    height: 720px;
    top: 10px;
    right: 20px;
    padding: 40px 32px;
    transform: none;
    left: auto;
    overflow: scroll;
  }

  .modal_steps_wrapper {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .cancel_button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.stepOne_container {
  margin-top: 50px;
  @media (min-width: 1280px) {
    margin-top: 0;
  }
  width: 100%;
  height: 100%;
  display: flex;
  //   align-items: center;
  flex-direction: column;

  .stepOne_header {
    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
    }
    h3 {
      color: #170d33;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }

  .stepOne_form {
    text-align: left;

    .verify_phone {
      margin-bottom: 14px;
      text-align: left;

      .phone {
        font-size: 90%;
        line-height: 12px;
        color: #8b8699;
      }

      .phone_label {
        color: #8b8699;
        font-size: 12px;
      }

      .selected_option {
        display: flex;
        align-items: center;
        gap: 16px;

        .flag {
          display: flex;
          gap: 10px;
          align-items: center;
          height: 40px;
          border: 0.5px solid #d1cfd6;
          border-radius: 10px;
          padding: 0 0.5rem;
          cursor: pointer;
          width: 115px;

          .iconify {
            margin-bottom: 1px;
            flex-shrink: 0;
            width: 16px;
            height: 10.643px;
          }

          .arrow {
            display: flex;
            width: 16px;
            height: 16px;
            justify-content: center;
            align-items: center;
            margin-bottom: 1px;
            flex-shrink: 0;
          }

          span {
            &:nth-child(2) {
              color: #8b8699;
              font-style: normal;
              font-size: 14px;
              width: 35px;
            }
          }
        }

        input {
          height: 40px;
          border: 0.5px solid #d1cfd6;
          border-radius: 10px;
          padding: 0 0.5rem;
        }

        input[type="tel"] {
          width: 100%;

          @media (min-width: 1280px) {
            width: 333px;
          }
        }
      }

      ol {
        list-style: none;
        overflow: overlay;
        max-height: 23rem;
        padding: 0;
        margin: 0;

        &::webkit-scrollbar {
          width: 0.6rem;
        }

        &::-webkit-scrollbar-thumb {
          width: 0.4rem;
          height: 3rem;
          background-color: #ccc;
          border-radius: 0.4rem;
        }

        li {
          padding: 1rem;
          align-items: center;
          display: flex;
          cursor: pointer;
          justify-content: space-between;

          div {
            display: flex;
            gap: 10px;
            align-items: center;

            .iconify {
              margin-bottom: 1px;
              flex-shrink: 0;
              width: 16px;
              height: 10.643px;
            }
          }

          &:hover {
            background-color: #bba4ff;
          }
        }
      }
      .phoneNumberValidation_error {
        color: rgb(205, 3, 3);
      }
    }

    .search_box {
      background-color: #200865;
      color: #fff;
      width: 100%;
      height: 50px;
      border: 0.5px solid #d1cfd6;
      border-radius: 10px 10px 0 0;
      padding: 0 0.5rem;
    }

    .options {
      position: relative;
      width: 100%;
      background-color: #f8f5ff;
      border-radius: 0.5rem;
      margin-top: 8px;

      &::before {
        position: absolute;
        content: "";
        left: 1rem;
        top: -1.1rem;
        height: 0;
        width: 0;
        border: 0.6rem solid transparent;
        border-bottom-color: #200865;
      }
    }
  }

  .submit__btn {
    margin-top: 70px;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;

    button {
      height: 50px;
      font-weight: 500;
      width: 100%;
      border-radius: 10px;
      cursor: pointer;
      border: none;
    }

    @media (min-width: 1280px) {
      margin-top: 50px;
    }
  }
}

.stepTwo_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .verification_success {
    svg {
      margin-bottom: 50px;
    }

    h3 {
      color: #3a434b;
      font-size: 18px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    p {
      color: #3a434b;
      font-family: "DM Sans";
      text-align: center;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }

  .submit__btn {
    width: 100%;
    margin-top: 100px;
    // margin-bottom: 68px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;

    button {
      height: 50px;
      font-weight: 500;
      width: 100%;
      border-radius: 10px;
      cursor: pointer;
      border: none;
    }
  }

  @media (min-width: 1280px) {
    .verification_success {
      svg {
        margin-bottom: 50px;
      }

      h3 {
        font-size: 24px;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}
