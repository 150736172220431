.modal_container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 200px);
    width: 90%;
    background-color: #FFF;
    border-radius: 20px;
    border-top: 4px solid #CFBEFF;
    box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.10);
    padding: 10px;
    position: fixed;

    @media (min-width: 1280px) {
        width: 528px;
        height: 720px;
        top: 10px;
        right: 20px;
        padding: 40px 32px;
        transform: none;
        left: auto;
    }

    .modal_steps_wrapper {
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .cancel_button {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

}