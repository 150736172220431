.card_container {
    width: 100%;
    position: relative;
    padding: 10px;

    .stepOne_header {
        h3 {
            color: #170D33;
            font-size: 20px;
            font-family: "DM Sans";
            font-weight: 700;
            font-style: normal;
            line-height: 150%;
        }
    }

    @media (min-width: 1280px) {
        width: 512px;
    }
}

.stepOne_form {
    margin-top: 30px;
    width: 100%;

    .fullName {
        display: flex;
        flex-direction: column;
    }
        
    @media (min-width: 1280px) {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        .fullName {
            flex-direction: row;
            gap: 20px;
        }
    }
}

.submit_button {
    margin-top: 20px;
    height: 45px;
    color: #fff;
    background-color: #713FFF;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    border: none;
}

.stepTwo_container {
    margin-top: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .arrowLeft {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .verification_success {
        width: 100%;
        text-align: center;
        svg {
            margin-bottom: 50px;
        }

        p {
            color: #3A434B;
            font-size: 18px;
            font-family: "DM Sans";
            font-weight: 700;
            font-style: normal;
            letter-spacing: 0.2px;
            line-height: 24px;
        }
    }
}

.left {
    position: absolute;
    top: 0;
    left: 20px;
    cursor: pointer;
}

.alignment {
    padding-top: 20px;
}