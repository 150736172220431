.residential_information_container {
  width: 100%;
  text-align: left;
  position: relative;

  .arrow {
    position: absolute;
    top: -30px;
    left: 0;
    cursor: pointer;

    @media (min-width: 1280px) {
      top: -40px;
      left: 0;
    }
  }

  .residential_information_header {
    margin-top: 50px;
    width: 100%;

    h3 {
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      margin-top: 32px;
      color: #200865;
      line-height: 125%; /* 40px */

      @media (min-width: 1280px) {
        font-size: 32px;
      }
    }

    p {
      font-size: 14px;
      font-style: normal;
      margin-top: 8px;
      font-weight: 400;
    }

    @media (min-width: 1280px) {
      margin-top: 30px;
    }
  }

  .residential_information_form {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    width: 100%;

    position: relative;

    .state_input_outer_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .state_input_label_container {
        display: flex;
        flex-direction: column;
        position: relative;

        .input_pointer {
          position: absolute;
          right: 5px;
          top: 35px;
        }
      }

      .stateNameDropdown {
        max-height: 200px;
        position: absolute;
        background-color: #fff;
        overflow-y: auto;
        color: #000;
        list-style: none;
        z-index: 100;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
        top: 65px;

        &::-webkit-scrollbar {
          width: 0.6rem;
        }

        &::-webkit-scrollbar-thumb {
          width: 0.4rem;
          height: 3rem;
          background-color: #200865;
          border-radius: 0.4rem;
        }
      }
    }

    .local_govt_input_outer_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 22px;
      position: relative;

      .local_govt_input_label_container {
        display: flex;
        flex-direction: column;
        position: relative;

        .input_pointer {
          position: absolute;
          right: 5px;
          top: 35px;
        }
      }

      .localGovtNameDropdown {
        max-height: 200px;
        position: absolute;
        background-color: #fff;
        overflow-y: auto;
        color: #000;
        list-style: none;
        z-index: 100;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
        top: 65px;

        &::-webkit-scrollbar {
          width: 0.6rem;
        }

        &::-webkit-scrollbar-thumb {
          width: 0.4rem;
          height: 3rem;
          background-color: #200865;
          border-radius: 0.4rem;
        }
      }
    }

    .input_field {
      width: 100%;
      height: 40px;
      flex-shrink: 0;
      border-radius: 10px;
      border: 0.5px solid #d1cfd6;
      background-color: #fff;
      padding: 0 0.5rem;

      @media (min-width: 1280px) {
        width: 464px;
      }
    }

    label {
      color: #8b8699;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  @media (min-width: 1280px) {
    width: 464px;
  }

  button {
    margin-top: 100px;
    width: 100%;
    border-radius: 10px;
    height: 45px;

    @media (min-width: 1280px) {
      margin-top: 100px;
    }

    @media (max-width: 375px) {
      margin-top: 80px;
    }
  }

  .alert {
    color: rgb(204, 10, 10);
    font-size: 13px;
  }
}
