.dueAmountContainer {
  height: 132px;
  flex-shrink: 0;
  border-radius: 18.38px;
  background: #f8f5ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .title {
    color: #8b8699;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .credit_balance {
    color: #200865;

    strong {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  @media (min-width: 1280px) {
    width: 462px;

    .title {
      color: #8b8699;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .credit_balance {
      color: #200865;

      strong {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
