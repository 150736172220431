.stepOne_container {
  .stepOne_header {
    margin-top: 32px;

    h1 {
      margin-top: 30px;
      font-size: 24px;
      color: #200865;

      @media (min-width: 1024px) {
        font-size: 32px;
      }
    }

    p {
      font-size: 14px;
      margin-top: -10px;
    }
  }
}

.stepOne_form {
  position: relative;
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .select_field {
    display: flex;
    flex-direction: column;

    label {
      font-size: 14px;
    }
  }

  @media (min-width: 1280px) {
    width: 464px;
  }

  .id_status {
    color: #200865;
  }

  .input_container {
    margin-bottom: 14px;

    .input_label_container {
      .email_label {
        font-size: 90%;
        color: #8b8699;
        line-height: 12px;
      }

      input {
        height: 40px;
        border: 0.5px solid #d1cfd6;
        border-radius: 10px;
        padding: 0 0.5rem;
      }

      input[type="email"] {
        width: 100%;

        @media (min-width: 1280px) {
          width: 464px;
        }
      }
    }

    .email_error {
      color: rgb(205, 3, 3);
    }
  }
}

.verify_phone {
  margin-bottom: 14px;

  .phone_label {
    font-size: 90%;
    color: #8b8699;
    line-height: 12px;
  }

  .selected_option {
    display: flex;
    align-items: center;
    gap: 16px;

    .flag {
      display: flex;
      gap: 10px;
      align-items: center;
      height: 40px;
      border: 0.5px solid #d1cfd6;
      border-radius: 10px;
      padding: 0 0.5rem;
      cursor: pointer;
      width: 115px;

      .iconify {
        margin-bottom: 1px;
        flex-shrink: 0;
        width: 16px;
        height: 10.643px;
      }

      .arrow {
        display: flex;
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        margin-bottom: 1px;
        flex-shrink: 0;
      }

      span {
        &:nth-child(2) {
          color: #8b8699;
          font-style: normal;
          font-size: 14px;
          width: 35px;
        }
      }
    }

    input {
      height: 40px;
      border: 0.5px solid #d1cfd6;
      border-radius: 10px;
      padding: 0 0.5rem;
    }

    input[type="tel"] {
      width: 100%;

      @media (min-width: 1280px) {
        width: 333px;
      }
    }
  }

  ol {
    list-style: none;
    overflow: auto;
    max-height: 23rem;
    padding: 0;
    margin: 0;

    &::-webkit-scrollbar {
      width: 0.6rem;
    }

    &::-webkit-scrollbar-thumb {
      width: 0.4rem;
      height: 3rem;
      background-color: #200865;
      border-radius: 0.4rem;
    }

    li {
      padding: 1rem;
      align-items: center;
      display: flex;
      cursor: pointer;
      justify-content: space-between;

      div {
        display: flex;
        gap: 10px;
        align-items: center;

        .iconify {
          margin-bottom: 1px;
          flex-shrink: 0;
          width: 16px;
          height: 10.643px;
        }
      }

      &:hover {
        background-color: #bba4ff;
      }
    }
  }
  .phoneNumberValidation_error {
    color: rgb(205, 3, 3);
  }
}

.search_box {
  background-color: #200865;
  color: #fff;
  width: 100%;
  height: 50px;
  border: 0.5px solid #d1cfd6;
  border-radius: 10px 10px 0 0;
  padding: 0 0.5rem;
}

.options {
  position: relative;
  width: 100%;
  background-color: #f8f5ff;
  border-radius: 0.5rem;
  margin-top: 8px;

  &::before {
    position: absolute;
    content: "";
    left: 1rem;
    top: -1.1rem;
    height: 0;
    width: 0;
    border: 0.6rem solid transparent;
    border-bottom-color: #200865;
  }
}

.submit__section {
  margin-top: 40px;
  text-align: center;
  color: #713fff;

  button {
    font-size: 16px;
  }

  p {
    font-size: 14px;
    margin-top: 13px;
  }
}

.submit__btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 45px;
    color: #fff;
    background-color: #713fff;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    border: none;
  }

  p {
    @media (max-width: 390px) {
      margin-top: 16px;
    }
  }
}

aside {
  width: 100%;

  @media (min-width: 1280px) {
    width: 464px;
  }
  font-size: 12px;
  color: #8b8699;
}

.validate_aggregator {
  margin-top: 30px;
  color: #200865;
  

  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 100px);

    .validating_text {
      padding-top: 20px;
      font-size: 16px;
    }
  }
}
