.container {
    height: 100dvh;
    margin-top: 100px;
    width: 100%;

    @media (min-width: 1280px) {
        display: none;
        margin: 0;
    }

    .transaction_header {
        padding-top: 20px;

        .button {
            display: flex;
            align-items: center;
            gap: 10px;

            .arrow {
                margin-top: -8px;
                width: 24px;
                height: 24px;
            }
        }

        .title {
            color: #200865;
            font-style: normal;
            font-size: 24px;
            line-height: 125%;
            font-weight: 700;
            padding-top: 20px;
        }

        .range {
            width: 100%;
            border: 1px solid #8B8699;
            border-radius: 5px;
            height: 50px;
        }
    }

    .transactions {
        width: 100%;
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .transaction {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #EEE;
            padding-bottom: 8px;

            .icon_title_wrap {
                display: flex;
                align-items: center;
                gap: 16px;

                .depositIcon_wrap {
                    width: 40px;
                    flex-shrink: 0;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #EAFBF1;
                    border-radius: 50%;
                }

                .withdrawalIcon_wrap {
                    width: 40px;
                    flex-shrink: 0;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FCE9E9;
                    border-radius: 50%;
                }

                .deposit_date {
                    display: flex;
                    flex-direction: column;

                    .transaction_type {
                        color: #3A3A3A;
                        font-style: normal;
                        font-size: 14px;
                        line-height: 140%;
                        font-weight: 500;
                    }

                    .transaction_date {
                        color: #8B8699;
                        font-style: normal;
                        font-size: 12px;
                        line-height: 100%;
                        font-weight: 400;
                    }
                }
            }

            .amount {
                align-self: flex-start;
                // color: #27BE63;
                font-style: normal;
                font-size: 14px;
                line-height: 150%; 
                font-weight: 500;
            }
        }
    }

    .noTransaction_container {
        width: 100%;
        height: calc(100% - 150px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;
        padding: 0 50px;
        text-align: center;

        h3 {
            font-size: 20px;
        }
    }
}