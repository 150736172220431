.layout_container {
    width: 100%;
    padding: 0 24px;
    // min-height: 100dvh;
    margin: 0 auto;
    // overflow-x: hidden;
    // overflow-y: scroll;
    
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .logo_wrapper {
            margin-top: 9.5px;
            margin-bottom: 13.91px;
            display: flex;
            align-items: center;
            gap: 4.44px;

            .logo {
                color: #200865;
                font-size: 22.222px;
                line-height: 150%;
                font-weight: 500;
                letter-spacing: -0.222px;
            }
        }

        .hamburger {
            width: 24px;
            height: 24px;
            margin-top: -10px;
        }

    }

    @media (min-width: 1280px) {
        // overflow-x: hidden;
        // overflow-y: hidden;
        padding: 0;
        .header {
            .hamburger {
                display: none;
            }

            .logo_wrapper {
                margin-top: 24.26px;
                margin-bottom: 24.74px;
                padding-left: 32px;
                align-items: center;
                display: flex;
                gap: 10px;
    
                .logo {
                    color: #200865;
                    font-size: 26.133px;
                    font-weight: 500;
                    font-style: normal;
                    letter-spacing: -0.261px;
                    line-height: 150%;
                }
            }
        }
    }
}

.layout_content_wrapper {
    width: 100%;

    @media (min-width: 1280px) {
        display: flex;

        .children_container {
            margin-top: -40px;
        }
    }
}