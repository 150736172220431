.overview_container {
    width: 100%;
    // overflow-x: hidden;
    // overflow-y: scroll;
        
    @media (min-width: 1280px) {
        width: 963px;
        // overflow-x: hidden;
        // overflow-y: hidden;
    }
}
