.modal_container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 200px);
    width: 90%;
    background-color: #FFF;
    border-radius: 20px;
    border-top: 4px solid #CFBEFF;
    box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.10);
    padding: 10px;
    position: fixed;
    overflow: scroll;
    z-index: 10;

    @media (min-width: 1280px) {
        width: 528px;
        height: 720px;
        top: 10px;
        right: 20px;
        padding: 40px 32px;
        transform: none;
        left: auto;
        overflow: scroll;
    }

    .back_button {
        position: absolute;
        top: 20px;
        left: 20px;
        cursor: pointer;
    }
    
    .cancel_button {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .modal_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;

        @media (max-width: 290px) and (max-height: 654px) {
            flex-direction: column;
        }

        @media (min-width: 1280px) {
            margin-top: 20px;
        }

        .loan_amount {
            color: #170D33;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.2px;
            font-family: "DM Sans";

            @media (min-width: 1280px) {
                font-size: 24px;
            }
        }

        .amountAndFlag_outer_container {
            width: 100%;
            position: relative;

            .new_offer {
                position: absolute;
                right: -100px;
                top: -40px;
                background-color: #FEF4E5;
                color: #C37E0B;
                padding: 8px;
                border-radius: 10px;


                @media (min-width: 1280px) {
                    right: -50px;
                    top: -40px;
                }

            }

            .amountAndFlag {
                display: flex;
                align-items: center;
                gap: 20px;

                .flag {
                    color: #25AD25;
                    font-family: "DM Sans";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px; /* 133.333% */
                    text-align: right;
                    background-color: #dcf5dc;
                    padding: 3px 7px;
                    border-radius: 10px;
                }
            }
        }

        .repayment_btn {
            color: #713FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 14px */
            font-family: "DM Sans";
            place-self: start;

            @media (max-width: 375px) and (max-height: 667px) {
                font-size: 12px;
            }

            @media (max-width: 290px) and (max-height: 654px) {
                place-self: center;
                margin-top: 10px;
            }
        }

        .title {
            color: #81909D;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            font-family: "DM Sans";
        }
    }

    table {
        width: 100%;
        display: block;
        border-collapse: collapse;
        margin-top: 16px;
        overflow: hidden;

        td {
            text-align: left;
            width: 100%;

            &:first-of-type {
                color: #8B8699;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                font-family: "DM Sans";
            }

            &:last-of-type {
                text-align: right;
                color: #150849;
                font-family: "DM Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-align: right;
            }

            padding: 10px;
            border-bottom: 1px solid #e1e1e1;
        }
    }
}

.submit_button {
    text-align: center;
    margin-top: 50px;
    width: 100%;
    display: flex;
    gap: 10px;


    button {
        height: 50px;
        font-weight: 500;
        width: 100%;
        border-radius: 10px;
        cursor: pointer;
        border: none;
    }
    
}

.isLoading_container {
    width: 100%;
    display: flex;
    padding: 24px;
    gap: 7.106px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #F8F5FF;

    margin-top: 50px;

    @media (min-width: 1280px) {
      margin-top: 10px;
    }
}

.flagActive {
    color: #25AD25;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  
  .flagPending {
    color: #0822a1;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  .flagRejected {
    color: #fe5e2e;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  .flagStale {
    color: #4a4848;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
  }