.mobile_menu_container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 30;
    background-color: #fff;
    border-top: 2px solid #713FFF;
    width: 0;
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
    //margin-top: 50px;
    overflow: hidden;

    height: 100dvh;
    display: flex;
    align-items: center;
    flex-direction: column;
    
    color:#8B8699;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    padding-top: 32px;

    .overview_header {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4px;

        .hamburger {
            position: absolute;
            top: 10px;
            right: 20px;
        }

        .avatar {
            height: 50px;
            width: 50px;
            border: 2px solid #6666FF;
            border-radius: 10px;
            margin-bottom: 10px;
        }

        .greeting {
            color: #3A434B;
            font-style: normal;
            font-size: 24px;
            line-height: 150%;
            font-weight: 700;
            letter-spacing: -0.16px;
        }
    }
    
    .sidebar {
        margin-top: 10px;
    
        .list_container {
            list-style: none;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 20px;
    
            .list_child {
    
                .list_child_wrap {
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        font-size: 18px;
                    }
                    a {
                        .list_child_wrap {
                            color:#8B8699;
                        }
            
                        &.selected {
                            .list_child_wrap {
                                color: #713FFF;
                            }
    
                        }
                    }
                }
            }
    
        }
    
        button {
            margin-top: 50px;
            padding-right: 32px;
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 18px;
        }
    
        .icon {
            margin-bottom: 2px;
        }

    @media (min-width: 1280px) {
        display: none;
    }

    
}

.mobile_menu_container.open {
    width: 80%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
}