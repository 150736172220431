.modal_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 200px);
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  border-top: 4px solid #cfbeff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  overflow: scroll;
  z-index: 10;

  @media (min-width: 1280px) {
    width: 528px;
    height: 720px;
    top: 10px;
    right: 20px;
    padding: 40px 32px;
    transform: none;
    left: auto;
  }

  .modal_steps_wrapper {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .cancel_button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.stepOne_container {
  margin-top: 30px;
  @media (min-width: 1280px) {
    margin-top: 0;
  }

  width: 100%;
  height: 100%;
  display: flex;
  // align-items: center;
  flex-direction: column;
  // justify-content: center;
  // gap: 100px;

  .stepOne_header {
    // padding-top: 50px;
    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
    }
    h3 {
      color: #170d33;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    p {
      color: #3a434b;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .stepOne_form {
    text-align: left;
    margin-top: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    // gap: 50px;

    .amount_container {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .amountInput_label_container {
        display: flex;
        flex-direction: column;
        flex: 1;

        .amount_input_label {
          font-size: 90%;
          color: #8b8699;
          line-height: 12px;
        }

        .amount_field {
          display: block;
          height: 40px;
          padding: 0 0.7rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #1e2022;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #d7dce0;
          border-radius: 10px;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          width: 100%;
        }
      }

      .amount_error_container {
        color: rgb(204, 10, 10);
      }
    }

    .charges {
      position: absolute;
      right: 10px;
      top: 5px;
      font-size: 12px;
      color: #713fff;
      background-color: #e0d7fa;
      border-radius: 5px;
      padding: 2px 5px;
    }

    .selectBankContainer {
      width: 100%;
      position: relative;
      margin-bottom: 8px;

      // @media (min-width: 400px) and (max-width: 470px) {
      //   margin-top: -20px;
      // }

      .bankNameDropdown {
        max-height: 150px;
        position: absolute;
        background-color: #fff;
        overflow-y: auto;
        color: #000;
        list-style: none;
        z-index: 100;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
      }

      .selectedBankField {
        width: 100%;
        border: 1px solid #d1cfd6;
        padding: 6px 10px;
        border-radius: 8px;
        font-size: 16px;

        &::placeholder {
          color: #8c98a4;
          opacity: 1;
        }
      }
    }

    .user {
      display: flex;
      align-items: center;
      gap: 8px;

      margin-bottom: 10px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    // .submit_button {
    //   margin-top: 50px;
    //   margin-bottom: 40px;
    //   @media (min-width: 1280px) {
    //     margin-bottom: 20px;
    //   }
    // }
  }
}

.stepTwo_container {
  margin-top: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  // position: relative;

  .prompt {
    position: absolute;
    top: 50px;
    background-color: #cfbeff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #7862b2;
    width: 250px;

    @media (min-width: 600px) {
      top: 20px;
      width: fit-content;
    }
  }

  .stepTwo_header {
    h3 {
      color: #170d33;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }

  .stepTwo_instruction {
    margin-top: 30px;
    .transfer_amount {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .instruction {
      text-align: left;
    }
  }

  .submit_button {
    margin-top: 100px;

    @media (max-width: 360px) and (max-height: 740px) {
      margin-top: 60px;
    }

    @media (max-width: 540px) and (max-height: 720px) {
      margin-top: 60px;
    }

    @media (max-width: 290px) and (max-height: 653px) {
      margin-top: 20px;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      margin-top: 60px;
    }
  }

  .backArrow {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }
}

.marginTop {
  margin-top: 10px;
}

.stepThree_container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.stepThree_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .verification_success {
    svg {
      margin-bottom: 50px;
    }

    h3 {
      color: #3a434b;
      font-size: 18px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    p {
      color: #3a434b;
      font-family: "DM Sans";
      text-align: center;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }

  .submit_button {
    margin-top: 100px;
  }

  @media (min-width: 1280px) {
    .verification_success {
      svg {
        margin-bottom: 50px;
      }

      h3 {
        font-size: 24px;
      }
    }
  }
}

.stepFour_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .verification_failed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box {
      width: 74px;
      height: 74px;
      background-color: #fff;
      border: 3px solid #000;
      border-radius: 50%;
      position: relative;
      margin-bottom: 50px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 35px;
        background-color: #e43535;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    h3 {
      color: #3a434b;
      font-size: 18px;
      font-family: "DM Sans";
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    p {
      color: #3a434b;
      font-family: "DM Sans";
      text-align: center;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }

  .submit_button {
    margin-top: 100px;
  }

  @media (min-width: 1280px) {
    .verification_failed {
      h3 {
        font-size: 24px;
      }
    }
  }
}

.stepFive_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 290px) and (max-height: 653px) {

    .content_wrap {
      .content {
        .title {
          font-size: 18px;
        }
      }
    }
  }
}

.cancel_button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.skip_button {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.content_wrap {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .featureIcon {
    width: 144px;
    flex-shrink: 0;
    height: 144px;
    border-radius: 50%;
    background-color: #f8f5ff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }

  .content {
    width: 100%;

    .title {
      color: #170d33;
      font-size: 20px;
      text-align: center;
      font-weight: 700;
      font-style: normal;
      line-height: 125%;
    }

    .content_body {
      color: #8b8699;
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0.4px;
      line-height: 22px;
    }
  }

  @media (min-width: 1280px) {
    .content {
      width: 361px;

      .title {
        font-size: 32px;
      }
    }
  }
}

.stepSix_container {
  width: 100%;
  margin-top: 40px;

  .stepSix_header {
    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
    }
    .title {
      margin-bottom: 10px;

      @media (max-width: 290px) and (max-height: 654px) {
        h3 {
          font-size: 16px;
        }
      }
    }
  }

  .stepSix_form {
    width: 100%;
    text-align: left;

    .selectQuestionContainer {
      width: 100%;
      position: relative;
      margin-bottom: 8px;

      .questionDropdown {
        max-height: 150px;
        position: absolute;
        background-color: #fff;
        overflow-y: auto;
        color: #000;
        list-style: none;
        z-index: 100;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
      }
      .selectedQuestionField {
        width: 100%;
        border: 1px solid #d1cfd6;
        padding: 6px 10px;
        border-radius: 8px;
        font-size: 16px;
        &::placeholder {
          color: #8c98a4;
          opacity: 1;
        }
      }
    }
  }
}

.stepSeven_container {
  width: 100%;

  .stepSeven_header {
    margin-top: 30px;

    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
    }
  }

  .stepSeven_form {
    width: 100%;
    margin-top: 30px;
    text-align: left;
  }
}

.submit_button {
  margin-top: 50px;
  padding-bottom: 40px;
  font-weight: 500;
  width: 100%;

  button {
    width: 100%;
    height: 45px;
    border-radius: 10px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.errorStyles {
  display: inline-block;
  color: rgb(205, 3, 3);
  margin-top: 4px;
  font-size: 13px;
}
