.personalInfo_container {
    width: 100%;
    position: relative;
    padding: 10px;

    .header {
        h3 {
            color: #170D33;
            font-size: 20px;
            font-family: "DM Sans";
            font-weight: 700;
            font-style: normal;
            line-height: 150%;
        }
    }

    @media (min-width: 1280px) {
        width: 512px;
    }
}

.alignLeft {
    text-align: left;
}

.form {
    width: 100%;

    .fullName {
        display: flex;
        flex-direction: column;
    }
    @media (min-width: 1280px) {
        .fullName {
            flex-direction: row;
            gap: 20px;
        }

    }

    .verify_phone {
        margin-bottom: 14px;

        .phone {
            font-size: 90%;
            color: #8B8699;
            line-height: 12px;
        }
    
        .selected_option {
            display: flex;
            align-items: center;
            gap: 16px;
    
            .flag {
                display: flex;
                gap: 10px;
                align-items: center;
                height: 40px;
                border: 0.5px solid #D1CFD6;
                border-radius: 10px;
                padding: 0 0.5rem;
                cursor: pointer;
                width: 115px;
    
                .iconify {
                    margin-bottom: 1px;
                    flex-shrink: 0;
                    width: 16px;
                    height: 10.643px;
                }
    
                .arrow {
                    display: flex;
                    width: 16px;
                    height: 16px;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1px;
                    flex-shrink: 0;
                }
    
                span {
    
                    &:nth-child(2) {
                        color: #8B8699;
                        font-style: normal;
                        font-size: 14px;
                        width: 35px;
                    }
                }
            }
    
            input {
                height: 40px;
                border: 0.5px solid #D1CFD6;
                border-radius: 10px;
                padding: 0 0.9rem;
            }
    
            input[type="tel"] {
                width: 100%;
                font-size: 17px;
    
                @media (min-width: 1280px) {
                    width: 100%;
                }
            }
        }
    
        ol {
            list-style: none;
            overflow: overlay;
            max-height: 23rem;
            padding: 0;
            margin: 0;
    
            &::webkit-scrollbar {
                width: 0.6rem;
            }
    
            &::-webkit-scrollbar-thumb {
                width: 0.4rem;
                height: 3rem;
                background-color: #ccc;
                border-radius: .4rem;
            }
    
            li {
                padding: 1rem;
                align-items: center;
                display: flex;
                cursor: pointer;
                justify-content: space-between;
    
                div {
                    display: flex;
                    gap: 10px;
                    align-items: center;
    
                    .iconify {
                        margin-bottom: 1px;
                        flex-shrink: 0;
                        width: 16px;
                        height: 10.643px;
                    }
                }
    
                &:hover {
                    background-color: #bba4ff;
                }
            }
        }
        .phoneNumberValidation_error {
            color: rgb(205, 3, 3);
        }
    }
    
    .search_box {
        background-color: #200865;
        width: 100%;
        color: #fff;
        border: 0.5px solid #D1CFD6;
        height: 50px;
        padding: 0 0.5rem;
        border-radius: 10px 10px 0 0;
    }
    
    .options {
        position: relative;
        width: 100%;
        background-color: #F8F5FF;
        border-radius: .5rem;
        margin-top: 8px;
    
        &::before {
            position: absolute;
            content: "";
            left: 1rem;
            top: -1.1rem;
            height: 0;
            width: 0;
            border: .6rem solid transparent;
            border-bottom-color: #200865;
        }
    }

    @media (min-width: 1280px) {
        .fullName {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
    
    }
}

.submit__btn {
    margin-top: 70px;
    // margin-bottom: 68px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;

    button {
        height: 50px;
        font-weight: 500;
        width: 100%;
        border-radius: 10px;
        cursor: pointer;
        border: none;
    }
}

.stepTwo_container {
    margin-top: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .arrowLeft {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .verification_success {
        width: 100%;
        text-align: center;
        svg {
            margin-bottom: 50px;
        }

        p {
            color: #3A434B;
            font-size: 18px;
            font-family: "DM Sans";
            font-weight: 700;
            font-style: normal;
            letter-spacing: 0.2px;
            line-height: 24px;
        }
    }
}

.left {
    position: absolute;
    top: 0;
    left: 20px;
    cursor: pointer;
}

.alignment {
    padding-top: 20px;
}