.modal_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 200px);
  width: 90%;
  background-color: #fff;
  border-radius: 20px;
  border-top: 4px solid #cfbeff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  overflow: scroll;
  z-index: 10;

  @media (min-width: 1280px) {
    width: 528px;
    height: 720px;
    top: 10px;
    right: 20px;
    padding: 40px 32px;
    transform: none;
    left: auto;
  }

  .cancel_button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.stepOne_container {
  .stepOne_header {
    padding-top: 25px;
    text-align: center;

    @media (min-width: 1280px) {
      text-align: left;
    }

    h3 {
      color: #170d33;
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      line-height: 32px;
    }

    p {
      text-align: left;
      color: #8b8699;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: 150%; /* 24px */
    }

    @media (max-width: 375px) and (max-height: 667px) {
      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .loanOffer_container {
    width: 100%;
    height: max-content;
    background: #f8f5ff;
    flex-shrink: 0;
    border-radius: 10px;

    .loanOffer_overview {
      width: 100%;
      display: flex;
      flex-direction: column;

      .loan_amount_outer_container {
        border-bottom: 1px solid #D7DCE0;

        .loan_amount_container {
          color: #041644;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 125%; /* 40px */
          padding: 24px 32px 0 32px;

          @media (min-width: 1280px) {
            font-size: 32px;
          }
        }
      }

      .more_loan_details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
        padding: 24px 32px 40px 32px;

        .loan_detail {
          display: flex;
          flex-direction: column;

          span:first-child {
            color: #645E77;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 12px */
          }

          span:last-child {
            color: #0D0F11;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
          }
        }

      }
    }


    @media (min-width: 1280px) {
      width: 462px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 32px;

    button {
      width: 100%;
      border-radius: 10px;
      height: 45px;
    }

    @media (min-width: 1280px) {
      margin-top: 92px;
    }
  }
}

.submit_button {
  margin-top: 70px;
  margin-bottom: 30px;
  height: 45px;
  color: #fff;
  background-color: #713fff;
  font-weight: 500;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.submit_button_loading_state {
  margin-top: 70px;
  margin-bottom: 30px;
  height: 45px;
  color: #fff;
  background-color: #713fff;
  font-weight: 500;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
}

.stepTwo_container {
  width: 100%;
  height: 100%;
  margin-top: 50%;
  text-align: center;

  @media (min-width: 1280px) {
    margin-top: 40%;
  }

  .verification_success {
    img {
      margin-bottom: 50px;
    }

    h3 {
      color: #3a434b;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.2px;
      font-style: normal;
    }

    p {
      color: #3a434b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-align: center;
    }
  }

  .submit_button {
    margin-top: 150px;

    @media (min-width: 1280px) {
      margin-top: 140px;
    }

    @media (max-width: 375px) and (max-height: 667px) {
      margin-top: 80px;
    }
  }
}

.stepThree_container {
  width: 100%;
  height: 100%;
  margin-top: 50%;
  text-align: center;

  @media (min-width: 1280px) {
    margin-top: 40%;
  }

  .verification_failed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box {
      width: 74px;
      height: 74px;
      background-color: #fff;
      border: 3px solid #000;
      border-radius: 50%;
      position: relative;
      margin-bottom: 50px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 35px;
        background-color: #e43535;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    h3 {
      color: #3a434b;
      font-size: 18px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    p {
      color: #3a434b;
      text-align: center;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 32px;

    button {
      width: 100%;
      border-radius: 10px;
      height: 45px;
    }

    @media (min-width: 1280px) {
      margin-top: 92px;
    }
  }
}

.stepFour_container {
  .stepFour_header {
    padding-top: 50px;
    text-align: left;

    @media (min-width: 1280px) {
      padding-top: 40px;
    }

    h3 {
      color: #170D33;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 133.333% */
    }

    p {
      color: #8B8699;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }

  .loanOffer_next_steps_body {
    margin-top: 32px;
    p {
      color: #170D33;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }

    .account_details_outer_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 32px 0;


      .account_details_container {
        display: flex;
        padding: 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 20px;
        border: 1px solid #713FFF;
        background-color: #F8F5FF;

        .account_number_bank_name {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .account_number {
            color: #200865;
            font-feature-settings: 'liga' off, 'clig' off;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 80% */
          }

          .bank {
            color: #200865;
            font-feature-settings: 'liga' off, 'clig' off;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 100% */
          }
        }

        .bank_provider {
          color: #200865;
          font-feature-settings: 'liga' off, 'clig' off;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 100% */
        }
      }

      .copy_btn_outer_container {
        place-self: center;

        .copy_btn_container {
          margin-top: 16px;
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }

    }
  }

  button {
    margin-top: 24px;
    width: 100%;
    border-radius: 10px;
    height: 45px;

    @media (min-width: 1280px) {
      margin-top: 100px
    }
  }

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}
