.login__container {
    height: 100vh;
    overflow: hidden;
    padding: 0 24px;
    color: #200865;
    width: 90%;
    margin: 0 auto;

    @media (min-width: 1280px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 70px;
    }
}

.logo__header {
    margin-top: 26px;
    display: flex;
    align-items: center;
    gap: 10px;

    .logo {
        font-size: 26.133px;
    }
}

.greeting {
    margin-top: 40px;

    header {
        h1 {
            font-size: 24px;
            color: #200865;
        }
        
        p {
            font-size: 14px;
        }
    }

    @media (min-width: 1280px) {
        header {
            h1 {
                font-size: 32px
            }
        }
    }

}

.form {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    
    @media (min-width: 1280px) {
        width: 464px;
        position: relative;
        margin-top: 40px;
    }

    .forgot__password {
        border: none;
        background-color: transparent;
        color: #200865;
        text-transform: capitalize;
        font-size: 16px;
        place-self: end;
    }

}

.submit__section {
    text-align: center;
    color: #713FFF;
    margin-top: 180px;
    width: 100%;

    p {
        font-size: 14px;
        margin-top: 13px;
    }

    button {
        height: 50px;
        // color: #fff;
        // background-color: #713FFF;
        font-weight: 500;
        width: 100%;
        border-radius: 10px;
        cursor: pointer;
        border: none;
    }
    

    @media (min-width: 1280px) {
        margin-top: 200px;
    }

    @media (min-width: 530px) and (max-height: 730px) {
        margin-top: 100px;
    }

    @media (min-width: 1024px) and (max-height: 600px) {
        margin-top: 60px;
    }

    @media (max-width: 375px) and (max-height: 667px) {
        margin-top: 100px;
    }
}

.svg__section {
    display: none;

    @media (min-width: 1280px) {
        display: block;
        background-color: #F8F5FF;
        height: 704px;
        max-width: 530px;
        margin-top: 114px;
        text-align: center;
        padding: 45px;
        border-radius: 40px 40px 0 0;
    
        h2 {
            font-size: 24px;
            font-weight: 700;
        }
    
        p {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            width: 352px;
            margin: 0 auto;
            line-height: 24px
        }

    }

}