* {
    padding: 0;
    margin: 0;
}
.sidebar_container {
    display: none; 

    @media (min-width: 1280px) {
        height: calc(100dvh - 100px);
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        width: 245px;
    
        color:#8B8699;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
    
        .sidebar {
    
            .list_container {
                list-style: none;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 14px;
    
                .list_child {
    
                    .list_child_wrap {
                        padding-left: 32px;
                        display: flex;
                        gap: 8px;
                        align-items: center;
                    }
                    a {
                        .list_child_wrap {
                            color:#8B8699;
    
                        }
            
                        &.selected {
                            .list_child_wrap {
                                color: #713FFF;
                                border-left: 2px solid #713FFF;
                            }
    
                        }
                    }
                }
            }
    
        }
    
        button {
            padding-left: 32px;
            display: flex;
            align-items: center;
            gap: 8px;
            border: none;
            background-color: transparent;
            
        }
    
        .icon {
            margin-bottom: 2px;
        }
    }
}