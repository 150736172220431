.registration_success_container {
  width: 100%;
  text-align: center;

  .registration_success_header {
    margin-top: 150px;
    width: 100%;

    h3 {
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      margin-top: 32px;
      color: #200865;
      line-height: 125%; /* 40px */

      @media (min-width: 1280px) {
        font-size: 32px;
      }
    }

    @media (min-width: 1280px) {
      margin-top: 80px;
    }

  }

  p {
    font-size: 14px;
    font-style: normal;
    margin-top: 8px;
    font-weight: 400;
  }

  @media (min-width: 1280px) {
    width: 464px;
    text-align: left;
  }

  button {
    margin-top: 150px;
    width: 100%;
    border-radius: 10px;
    height: 45px;

    @media (min-width: 1280px) {
      margin-top: 100px
    }
  }

}